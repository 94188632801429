<template>
  <modal
    class="clone-session-modal"
    name="clone-session-modal"
    transition="pop-out"
    :height="550"
  >
    <div class="position-relative">
      <div class="vm--top-right-slot" style="z-index: 1">
        <button
          class="btn p-0 pr-3 pt-3 border-0"
          @click="closeCloneSessionModal"
        >
          <i class="fas fa-times fa-lg"></i>
        </button>
      </div>
      <div class="card-body" v-if="eventToClone && !loading">
        <div class="row border-bottom h-406 relative-position">
          <label class="col-2 font-weight-bold pt-2">Date</label>
          <div class="mt-2 pt-1 col-10">
            <div class="custom-control custom-checkbox pb-4">
              <input
                type="radio"
                name="cloneDateType"
                id="date-range-enabled"
                value="dateRange"
                class="custom-control-input"
                v-model="cloneFilter.dateRangeOption"
              />
              <label class="custom-control-label mr-3" for="date-range-enabled"
                >By Date Range</label
              >
            </div>
            <div
              class="col-12 mb-5 p-0"
              v-if="cloneFilter.dateRangeOption == 'dateRange'"
            >
              <div class="d-flex justify-content-between">
                <datetime
                  v-model="cloneFilter.dateRangeData.startDate"
                  :min-datetime="cloneFilter.minDate"
                  placeholder="End Date"
                  class="col-6"
                  :week-start="7"
                  input-class="col-md-12 form-control"
                  input-style="font-size: 12px;"
                  type="date"
                >
                </datetime>
                <datetime
                  v-model="cloneFilter.dateRangeData.endDate"
                  :min-datetime="cloneFilter.dateRangeData.startDate"
                  :week-start="7"
                  class="col-6"
                  placeholder="End Date"
                  input-class="col-md-12 form-control"
                  input-style="font-size: 12px;"
                  type="date"
                >
                </datetime>
              </div>
              <div class="radio-btn-group mt-4 pl-3">
                <div class="radio">
                  <input
                    type="radio"
                    name="weekDay"
                    id="sunday"
                    value="0"
                    v-model="cloneFilter.dateRangeData.weekDay"
                  />
                  <label for="sunday">Sun</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="weekDay"
                    id="monday"
                    value="1"
                    v-model="cloneFilter.dateRangeData.weekDay"
                  />
                  <label for="monday">Mon</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="weekDay"
                    id="tuesday"
                    value="2"
                    v-model="cloneFilter.dateRangeData.weekDay"
                  />
                  <label for="tuesday">Tue</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="weekDay"
                    id="wednesday"
                    value="3"
                    v-model="cloneFilter.dateRangeData.weekDay"
                  />
                  <label for="wednesday">Wed</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="weekDay"
                    id="thursday"
                    value="4"
                    v-model="cloneFilter.dateRangeData.weekDay"
                  />
                  <label for="thursday">Thu</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="weekDay"
                    id="friday"
                    value="5"
                    v-model="cloneFilter.dateRangeData.weekDay"
                  />
                  <label for="friday">Fri</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="weekDay"
                    id="saturday"
                    value="6"
                    v-model="cloneFilter.dateRangeData.weekDay"
                  />
                  <label for="saturday">Sat</label>
                </div>
              </div>
            </div>
            <div class="custom-control custom-checkbox pb-4">
              <input
                type="radio"
                name="cloneDateType"
                id="custom-dates-enabled"
                value="customDates"
                class="custom-control-input"
                v-model="cloneFilter.dateRangeOption"
              />
              <label
                class="custom-control-label mr-3"
                for="custom-dates-enabled"
                >By Custom Dates</label
              >
            </div>
            <div
              class="
                d-flex
                justify-content-between
                col-12
                mb-5
                py-0
                px-4
                custom-date-mobile
              "
              v-if="cloneFilter.dateRangeOption == 'customDates'"
            >
              <v-calendar
                :attributes="attributes"
                @dayclick="onDayClick"
                :min-date="cloneFilter.minDate"
                is-expanded
              />
            </div>
          </div>
          <div class="col-12 absolute-position bottom-0">
            <input
              type="checkbox"
              id="allowOverlapping"
              v-model="allowOverlap"
            />
            <label
              for="allowOverlapping"
              class="pointer-cursor ml-2 mb-0 text-dark-red"
              >Allow Overlapping With Other Sessions</label
            >
          </div>
        </div>
        <div class="saving-note-container" v-if="cloneFilter.isSaving">
          <div class="saving-note alert alert-info m-0 mb-1 py-1" role="alert">
            This may take few moments...
          </div>
        </div>

        <div class="my-4 d-flex justify-content-center">
          <save
            :saving="cloneFilter.isSaving"
            @click="sendCloneRequest"
            savingText="Clone Appointment"
            >Clone Appointment</save
          >
          <div class="btn-group">
            <button
              type="button"
              class="btn px-1 py-0 rounded"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="ml-2 fas fa-cog"></i>
            </button>
            <div
              class="dropdown-menu print-menu right-0 left-auto"
              @click="$event.stopPropagation()"
            >
              <h3 class="mx-3 my-2">
                <i class="fas fa-cog mr-2"></i>Clone Settings
              </h3>
              <div class="dropdown-divider"></div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="cloneSettings_expenses"
                    v-model="cloneSettings.expenses"
                  />
                  <label
                    class="custom-control-label"
                    for="cloneSettings_expenses"
                    >Expenses</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="cloneSettings_billingDetails"
                    v-model="cloneSettings.billingDetails"
                  />
                  <label
                    class="custom-control-label"
                    for="cloneSettings_billingDetails"
                    >Billing Details</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="cloneSettings_reason"
                    v-model="cloneSettings.reason"
                  />
                  <label class="custom-control-label" for="cloneSettings_reason"
                    >Reason</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="cloneSettings_memo"
                    v-model="cloneSettings.memo"
                  />
                  <label class="custom-control-label" for="cloneSettings_memo"
                    >Memo</label
                  >
                </div>
              </div>
              <div class="dropdown-item">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="cloneSettings_subServices"
                    v-model="cloneSettings.subServices"
                  />
                  <label
                    class="custom-control-label"
                    for="cloneSettings_subServices"
                    >Sub Services</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading">
        <alert class="m-4" />
      </div>
    </div>
  </modal>
</template>

<script>
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Datetime } from "vue-datetime";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "calendar-clone-modal",
  components: {
    Datetime,
  },
  data() {
    return {
      loading: true,
      eventToClone: undefined,
      allowOverlap: false,
      cloneFilter: {
        dateRangeOption: "dateRange",
        isSaving: false,
        dateRangeData: {
          weekDay: 0,
          startDate: "",
          endDate: "",
        },
        customDates: [],
        customDatesIds: [],
      },
      cloneSettings: {
        expenses: false,
        billingDetails: true,
        memo: true,
        reason: true,
        subServices: true,
      },
    };
  },
  created() {
    window.openCloneSessionModal = this.openCloneSessionModal.bind(this);
  },
  computed: {
    attributes() {
      return this.cloneFilter.customDates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
  },
  methods: {
    openCloneSessionModal: function (eventId) {
      this.allowOverlap = false;
      this.$http
        .get(`users/appointments/${eventId}`)
        .then((res) => {
          this.loading = false;
          this.eventToClone = ((res || {}).data || {}).data;
          if (!this.eventToClone) {
            return Swal.fire({
              title: "Error",
              text: `Something went wrong, please refresh the page and try again`,
              icon: "error",
            });
          }

          let hasInactiveServices = false;
          (this.eventToClone.services || []).forEach((serv) => {
            if (serv.status == "INACTIVE") {
              hasInactiveServices = true;
            }
          });

          if (hasInactiveServices) {
            return Swal.fire({
              title: "Warning",
              text: `Unable to clone events with inactive services`,
              icon: "warning",
            });
          }
          this.cloneFilter = {
            dateRangeOption: "dateRange",
            isSaving: false,
            dateRangeData: {
              weekDay: 0,
              startDate: dayjs(this.eventToClone.start_time)
                .add(1, "day")
                .format(),
              endDate: "",
            },
            minDate: dayjs(this.eventToClone.start_time).add(1, "day").format(),
            customDates: [],
            customDatesIds: [],
          };
          this.$modal.show("clone-session-modal");
        })
        .catch((err) => {
          return Swal.fire({
            title: "Error",
            text: (err.data || {}).message || "Something went wrong...",
            icon: "error",
          });
        });
    },
    closeCloneSessionModal: function () {
      this.$modal.hide("clone-session-modal");
    },
    sendCloneRequest: function () {
      this.cloneFilter.isSaving = true;
      if (this.cloneFilter.dateRangeOption == "dateRange") {
        const sessionDates = this.getAllSelectedDayInRange();
        this.cloneBook(sessionDates);
      } else if (this.cloneFilter.dateRangeOption == "customDates") {
        const sessionDates = this.getAllSelectedDates();
        this.cloneBook(sessionDates);
      }
    },
    getAllSelectedDayInRange: function () {
      const start = new Date(this.cloneFilter.dateRangeData.startDate);
      const end = new Date(this.cloneFilter.dateRangeData.endDate);
      const day = this.cloneFilter.dateRangeData.weekDay;
      const difference = dayjs(this.eventToClone.end_time).diff(
        this.eventToClone.start_time
      );

      const hours = this.eventToClone.start_time.split("T")[1];
      let current = start;
      const result = [];
      // Shift to next of required days
      current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));
      // While not exceeding end date, add dates to result array
      while (current <= end) {
        const utcCurrent = `${dayjs(current).format("YYYY-MM-DD")}T${hours}`;
        const canadaOffset = dayjs.tz(current, "America/Toronto").utcOffset();

        const sessionStart = dayjs(new Date(utcCurrent))
          .add(canadaOffset, "minute")
          .utc()
          .format()
          .replace(":00Z", ":00.000000Z");
        const sessionEnd = dayjs(sessionStart)
          .add(difference, "millisecond")
          .utc()
          .format()
          .replace(":00Z", ":00.000000Z");

        result.push({ start_time: sessionStart, end_time: sessionEnd });
        current.setDate(current.getDate() + 7);
      }
      return result;
    },
    getAllSelectedDates: function () {
      const difference = dayjs(this.eventToClone.end_time).diff(
        this.eventToClone.start_time
      );

      const result = [];
      this.cloneFilter.customDates.forEach((item) => {
        const canadaOffset = dayjs
          .tz(new Date(item), "America/Toronto")
          .utcOffset();
        const hours = this.eventToClone.start_time.split("T")[1];
        const current = `${dayjs(item).format("YYYY-MM-DD")}T${hours}`;

        const sessionStart = dayjs(new Date(current))
          .add(canadaOffset, "minute")
          .utc()
          .format()
          .replace(":00Z", ":00.000000Z");

        const sessionEnd = dayjs(sessionStart)
          .add(difference, "millisecond")
          .utc()
          .format()
          .replace(":00Z", ":00.000000Z");
        result.push({ start_time: sessionStart, end_time: sessionEnd });
      });
      return result;
    },
    cloneBook: function (sessionDates) {
      if (!sessionDates || !sessionDates.length) {
        this.cloneFilter.isSaving = false;
        const days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        if (this.cloneFilter.dateRangeOption == "dateRange") {
          return Swal.fire({
            title: "Warning",
            html: `Selected date range has no <b>${
              days[this.cloneFilter.dateRangeData.weekDay]
            }</b>`,
            icon: "warning",
          });
        }
        return Swal.fire({
          title: "Warning",
          text: "Please select at least one date",
          icon: "warning",
        });
      }
      let data = {
        session_dates: sessionDates,
        user_1: this.getProvider(this.eventToClone).user_id,
        user_2: this.getPatient(this.eventToClone).user_id,
        type: this.eventToClone.type,
        memo: this.cloneSettings.memo ? this.eventToClone.memo : "",
        reason: this.cloneSettings.reason ? this.eventToClone.reason : "",
        expenses: this.cloneSettings.expenses ? this.eventToClone.expenses : 0,
        billing_details: this.cloneSettings.billingDetails
          ? this.eventToClone.billing_details
          : "",
        provider_rate: this.eventToClone.provider_rate,
        billable: this.eventToClone.billable == "1",
        auto_schedule_zoom: !!(
          this.eventToClone.start_url || this.eventToClone.join_url
        ),
        virtual_url: "To Create", // Show popup on checkbox is checked.TODO
        allow_overlap: this.allowOverlap,
      };
      data.services = this.cloneSettings.subServices
        ? this.eventToClone.services
        : [];
      data.primary = (
        this.eventToClone.services.filter(
          (item) => !item.parent_service_id
        )[0] || {}
      ).id;

      data.services.forEach((service, index) => {
        if (service.pivot != null && service.pivot != undefined) {
          service.qty = service.pivot.quantity;
          service.rate = service.pivot.rate;
          service.unit = service.pivot.unit;
          service.taxable = service.pivot.taxable == "1" ? true : false;
          if (!service.description) {
            service.description = service.pivot.description;
          }
        }
        service.description = (service.description || "").substring(0, 500);
        if (index) {
          service.selected = true;
        }
        delete service["pivot"];
      });
      data.services = data.services.filter((item) => item.parent_service_id);
      this.$http
        .post("appointments-clone", data)
        .then(
          function (res) {
            if (res) {
              this.$emit("refetchCalendar");
              this.$modal.hide("clone-session-modal");
              this.cloneSettings = {
                expenses: false,
                billingDetails: true,
                memo: true,
                reason: true,
                subServices: true,
              };
              Swal.fire({
                title: "Appointment Cloned",
                text: "Appointment Cloned Successfully",
                icon: "success",
              });
            }
          }.bind(this)
        )
        .catch((err) => {
          if (!err.accessDenied) {
            let text =
              ((err.response || {}).data || {}).message ||
              (err.data || {}).message ||
              "Something went wrong...";
            const errors =
              ((err.response || {}).data || {}).errors ||
              (err.data || {}).errors ||
              [];
            if (errors.length) {
              text =
                'Below sessions haven\'t been cloned due to overlapping with other sessions for same user<br /><div class="border rounded mt-4 py-3"><b>';
              errors.forEach((error) => {
                const canadaOffset = dayjs
                  .tz(new Date(error), "America/Toronto")
                  .utcOffset();
                const sessioDate = dayjs(error)
                  .add(-1 * canadaOffset, "minute")
                  .utc()
                  .format("YYYY-MM-DD hh:mm A");
                text = `${text}${sessioDate}<br>`;
              });
              text = `${text}</div></b>`;
            }
            Swal.fire({
              title: "Issue While Cloning Appointment",
              html: text,
              icon: "error",
            });
          }
          this.$emit("refetchCalendar");
          this.$modal.hide("clone-session-modal");
        });
    },
    onDayClick: function (day) {
      const idx = this.cloneFilter.customDatesIds.findIndex(
        (id) => id === day.id
      );
      if (idx >= 0) {
        this.cloneFilter.customDates.splice(idx, 1);
        this.cloneFilter.customDatesIds.splice(idx, 1);
      } else if (!day.el.classList.contains("is-disabled")) {
        this.cloneFilter.customDates.push(day.date);
        this.cloneFilter.customDatesIds.push(day.id);
      }
    },
    getProvider: function (item) {
      let tempItem;
      if (item.provider) {
        tempItem = item.provider;
      } else {
        tempItem = item.user_1 || {};
        tempItem.user_id = tempItem.id;
      }
      return tempItem;
    },
    getPatient: function (item) {
      let tempItem;
      if (item.patient) {
        tempItem = item.patient;
      } else {
        tempItem = item.user_2 || {};
        tempItem.user_id = tempItem.id;
      }
      return tempItem;
    },
  },
};
</script>

<style scoped lang="scss">
.h-406 {
  height: 406px;
}

.right-0 {
  right: 0 !important;
}
.left-auto {
  left: auto !important;
}

.saving-note-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 112px;
  left: 0;
  right: 0;
  text-align: center;
  .saving-note {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
}
.radio-btn-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .radio {
    margin: 0 0.25rem;

    label {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      width: 50px;
      height: 42px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #444;
      transition: box-shadow 400ms ease;
      &:hover {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
          0 2px 10px 0 rgba(0, 0, 0, 0.12);
      }
    }

    input[type="radio"] {
      display: none;
    }

    input[type="radio"]:checked + label {
      background: #2a4c6c;
      color: #fff;
      border-color: #1e364d;
    }
  }
}

@media (max-width: 767px) {
  .custom-date-mobile {
    margin-left: -54px;
    width: calc(100% + 54px);
    max-width: calc(100% + 54px);
  }
}
</style>
