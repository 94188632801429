var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"calendar-print-header"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-4"},[(_vm.selectedPatient)?_c('p',{staticClass:"mb-0"},[_vm._v(" Client: "+_vm._s(_vm.selectedPatient.last)+", "+_vm._s(_vm.selectedPatient.first)+" ")]):_vm._e(),(_vm.profileAdminUserId && _vm.user)?_c('p',{staticClass:"mb-0"},[_vm._v(" Admin: "+_vm._s(_vm.user.last)+", "+_vm._s(_vm.user.first)+" ")]):_vm._e(),(
            _vm.selectedPatient &&
            _vm.patientData &&
            _vm.companyProfile &&
            _vm.companyProfile.show_insurer_in_report &&
            _vm.patientData.insurer
          )?_c('p',{staticClass:"mb-0"},[_vm._v(" Insurer: "+_vm._s(_vm.patientData.insurer)+" ")]):_vm._e(),(
            _vm.selectedPatient &&
            _vm.patientData &&
            _vm.companyProfile &&
            _vm.companyProfile.show_claim_number_in_report &&
            _vm.patientData.claim_number
          )?_c('p',{staticClass:"mb-0"},[_vm._v(" Claim #: "+_vm._s(_vm.patientData.claim_number)+" ")]):_vm._e(),(
            _vm.selectedPatient &&
            _vm.patientData &&
            _vm.companyProfile &&
            _vm.companyProfile.show_funding_source_in_report &&
            _vm.patientData.funding_source
          )?_c('p',{staticClass:"mb-0"},[_vm._v(" Funding Source: "+_vm._s(_vm.patientData.funding_source)+" ")]):_vm._e(),(_vm.selectedProvider)?_c('p',{staticClass:"mb-0"},[_vm._v(" Admin/Provider: "+_vm._s(_vm.selectedProvider.last)+", "+_vm._s(_vm.selectedProvider.first)+" ")]):_vm._e()]),_c('div',{staticClass:"col-4 text-center mt-4"},[(_vm.companyProfile)?_c('img',{staticClass:"company-logo",attrs:{"src":_vm.companyProfile.logo || _vm.companyLogoPlaceholder}}):_vm._e()]),_c('div',{staticClass:"col-4 text-right"},[(
            _vm.companyProfile &&
            _vm.companyProfile.show_claim_number_in_report &&
            _vm.companyProfile.claim_number
          )?_c('p',{staticClass:"mb-0"},[_c('label',[_vm._v("Claim Number: ")]),_vm._v(_vm._s(_vm.companyProfile.claim_number)+" ")]):_vm._e(),(
            _vm.companyProfile &&
            _vm.companyProfile.show_lawyer_in_report &&
            _vm.companyProfile.lawyer
          )?_c('p',{staticClass:"mb-0"},[_c('label',[_vm._v("Lawyer: ")]),_vm._v(_vm._s(_vm.companyProfile.lawyer)+" ")]):_vm._e(),(
            _vm.companyProfile &&
            _vm.companyProfile.show_location_in_report &&
            _vm.companyProfile.location
          )?_c('p',{staticClass:"mb-0"},[_c('label',[_vm._v("Location: ")]),_vm._v(_vm._s(_vm.companyProfile.location)+" ")]):_vm._e()])])]),_c('div',{staticClass:"print-only"},[_c('table',{staticClass:"table table-striped mb-0 calendar-table"},[_c('thead',[_vm._m(0),_c('tr',[_c('th',{staticClass:"text-center large-font",attrs:{"colspan":"9"}},[_vm._v(" "+_vm._s(_vm.calendarTitle)+" ")])]),_vm._m(1)]),_c('tbody',_vm._l((_vm.eventsToPrint),function(event){return _c('tr',{key:`event_${event.id}`},[_c('td',[_vm._v(_vm._s(_vm.dateFormat(event.start, "human")))]),_c('td',[_vm._v(" "+_vm._s(`${event.hours + event.sub_services_quantity} h${event.minutes > 0 ? " " + event.minutes + "m" : ""}`)+" ")]),_c('td',[_vm._v(_vm._s(event.main_service_name))]),_c('td',[_vm._v(_vm._s(event.provider_name))]),_c('td',[_vm._v(_vm._s(event.patient_name))]),_c('td',[_vm._v(_vm._s(event.reason))]),_c('td',[_vm._v(_vm._s(event.memo))]),_c('td',{staticClass:"text-capitalize"},[_vm._v(_vm._s(event.type))]),_c('td',{staticClass:"text-capitalize"},[_vm._v(_vm._s(event.status))])])}),0),_vm._m(2)])]),_c('div',{staticClass:"calendar-print-footer pt-2"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-6"},[(
            _vm.companyProfile &&
            _vm.companyProfile.show_name_in_report &&
            _vm.companyProfile.name
          )?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.companyProfile.name)+" ")]):_vm._e(),(
            _vm.companyProfile &&
            _vm.companyProfile.show_email_in_report &&
            _vm.companyProfile.email
          )?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.companyProfile.email)+" ")]):_vm._e()]),_c('div',{staticClass:"col-6 text-right"},[(
            _vm.companyProfile &&
            _vm.companyProfile.show_phone_number_in_report &&
            _vm.companyProfile.phone_number
          )?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.companyProfile.phone_number)+" ")]):_vm._e()]),_c('div',{staticClass:"col-12"},[(
            _vm.companyProfile &&
            _vm.companyProfile.show_bio_in_report &&
            _vm.companyProfile.bio
          )?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.companyProfile.bio)+" ")]):_vm._e(),(
            _vm.companyProfile &&
            _vm.companyProfile.show_url_in_report &&
            _vm.companyProfile.url
          )?_c('p',{staticClass:"mb-0 text-center"},[_vm._v(" "+_vm._s(_vm.companyProfile.url)+" ")]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('div',{staticClass:"calendar-print-table-header"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Duration")]),_c('th',[_vm._v("Service")]),_c('th',[_vm._v("Provider")]),_c('th',[_vm._v("Client")]),_c('th',[_vm._v("Reason")]),_c('th',[_vm._v("Memo")]),_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Status")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tfoot',[_c('tr',[_c('td',[_c('div',{staticClass:"calendar-print-table-footer"})])])])
}]

export { render, staticRenderFns }