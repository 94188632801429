<template>
  <div class="calendar-filters noPrint">
    <div
      class="customCalendarFilter"
      v-if="
        !profileProviderUserId && !profilePatientUserId && !profileAdminUserId
      "
    >
      <div class="row noPrint">
        <div
          class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
          v-if="user && !user.isPatient"
        >
          <select
            v-model="filter.classification"
            class="form-control light-shadow"
            @change="applyFilter()"
          >
            <option value="" selected>Filter by Class</option>

            <option v-for="p in options.classes" :key="p.id" :value="p.id">
              {{ p.label }}
            </option>
          </select>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
          <select
            v-if="!user || !user.isPatient"
            v-model="filter.Patient"
            class="form-control light-shadow"
            @change="applyFilter()"
          >
            <option value="" selected>Filter by Specific User/Client</option>
            <optgroup
              label="Admins"
              v-if="
                user &&
                (user.isAdmin || user.isManagingAdmin) &&
                options.adminUsers.length
              "
            >
              <option v-for="p in options.adminUsers" :key="p.id" :value="p.id">
                {{ p.last }}, {{ p.first }}
              </option>
            </optgroup>
            <optgroup
              label="Providers"
              v-if="options.providerUsers.length && user && !user.isProvider"
            >
              <option
                v-for="p in options.providerUsers"
                :key="p.id"
                :value="p.user_id || p.id"
              >
                {{ p.last }}, {{ p.first }} {{ p.user_id }}
              </option>
            </optgroup>
            <optgroup label="Clients" v-if="options.patientUsers.length">
              <option
                v-for="p in options.patientUsers"
                :key="p.user_id || p.id"
                :value="p.user_id || p.id"
              >
                {{ p.last }}, {{ p.first }}
              </option>
            </optgroup>
          </select>
          <select
            v-if="user && user.isPatient"
            v-model="filter.Provider"
            class="form-control light-shadow"
            @change="applyFilter()"
          >
            <option value="" selected>Filter by Specific Provider</option>
            <option
              v-for="p in options.providerUsers"
              :key="p.id"
              :value="p.user_id || p.id"
            >
              {{ p.last }}, {{ p.first }} {{ p.user_id }}
            </option>
          </select>
        </div>

        <div
          class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
          v-if="user && (user.isAdmin || user.isManagingAdmin)"
        >
          <select
            v-model="filter.Provider"
            class="form-control light-shadow"
            @change="applyFilter()"
          >
            <option value="" selected>Filter by Specific Provider/Admin</option>

            <optgroup
              label="Admins"
              v-if="
                user &&
                (user.isAdmin || user.isManagingAdmin) &&
                options.adminUsers.length
              "
            >
              <option v-for="p in options.adminUsers" :key="p.id" :value="p.id">
                {{ p.last }}, {{ p.first }}
              </option>
            </optgroup>
            <optgroup label="Providers" v-if="options.providerUsers.length">
              <option
                v-for="p in options.providerUsers"
                :key="p.id"
                :value="p.id"
              >
                {{ p.last }}, {{ p.first }}
              </option>
            </optgroup>
          </select>
        </div>

        <div
          class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
          v-if="user && !user.isPatient"
        >
          <select
            v-model="filter.billableStatus"
            class="form-control light-shadow"
            @change="applyFilter()"
          >
            <option value="" selected>Filter by Billable Status</option>

            <option v-for="p in billableStatuses" :key="p.id" :value="p.id">
              {{ p.label }}
            </option>
          </select>
        </div>
        <button
          v-if="user && (user.isAdmin || user.isManagingAdmin)"
          class="btn btn-theme ml-3 mb-3"
          @click="openBulkSessionsModal()"
        >
          Verify/Unverify Appointments
        </button>
        <button
          v-if="user && (user.isAdmin || user.isManagingAdmin)"
          class="btn btn-1a3f3f ml-3 mb-3"
          @click="openUsersAvailability()"
        >
          Users Availability
        </button>
      </div>
    </div>
    <div
      class="customCalendarFilter d-flex flex-wrap mt-4 align-items-center"
      v-if="
        user &&
        (profileAdminUserId ||
          (profilePatientUserId && !user.isPatient) ||
          (profileProviderUserId && (user.isAdmin || user.isManagingAdmin)))
      "
    >
      <div
        class="custom-control custom-checkbox form-group mr-3"
        v-if="
          user &&
          ((profilePatientUserId && !user.isPatient) ||
            (profileProviderUserId && (user.isAdmin || user.isManagingAdmin)))
        "
      >
        <input
          type="checkbox"
          class="custom-control-input"
          id="calendar_assignedToMe"
          v-model="filter.assignedToMe"
          @change="(filter.Provider = ''), applyFilter()"
        />
        <label class="custom-control-label" for="calendar_assignedToMe"
          >Assigned to me ({{ user.name }}) only</label
        >
      </div>
      <button
        v-if="user && (user.isAdmin || user.isManagingAdmin)"
        class="btn btn-theme mb-4"
        @click="openBulkSessionsModal()"
      >
        Verify/Unverify Appointments
      </button>
      <button
        v-if="user && (user.isAdmin || user.isManagingAdmin)"
        class="btn btn-1a3f3f ml-3 mb-4"
        @click="
          openUserAvailability(
            profileAdminUserId || profileProviderUserId || profilePatientUserId
          )
        "
      >
        {{
          getUserName(
            profileAdminUserId || profileProviderUserId || profilePatientUserId
          )
        }}'s Availability
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "calendar-filters",
  props: {
    options: {
      type: Object,
      required: true,
    },
    profileProviderUserId: {
      type: Number,
      default: 0,
    },
    profilePatientUserId: {
      type: Number,
      default: 0,
    },
    profileAdminUserId: {
      type: Number,
      default: 0,
    },
    users: Array,
  },
  data() {
    return {
      filter: {
        AppStatus: "",
        billableStatus: "",
        classification: "",
        Patient: "",
        Provider: "",
        assignedToMe: false,
      },
      billableStatuses: [
        { label: "Billable", id: "billable" },
        { label: "Non Billable", id: "non-billable" },
      ],
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      userInfo: (state) => state.auth.userInfo,
    }),
  },
  methods: {
    applyFilter: function () {
      this.$emit("filterChanged", this.filter);
    },
    openUsersAvailability: function () {
      window.open("/admin/users-availability", "_blank");
    },
    openUserAvailability: function (id) {
      this.$emit("openUserAvailability", id);
    },
    openBulkSessionsModal: function () {
      this.$emit("openBulkSessionsModal");
    },
    getUserName(id) {
      const user = this.users.filter((user) => user.id == id)[0];
      if (user) {
        return `${user.last}, ${user.first}`;
      }
      return "";
    },
  },
};
</script>
