<template>
  <modal
    class="bulk-sessions-modal"
    name="bulk-sessions-modal"
    transition="pop-out"
    :clickToClose="false"
    height="auto"
  >
    <div
      class="position-relative main-modal-container"
      ref="draggableContainer"
      @mousedown="dragMouseDown"
    >
      <div class="vm--top-right-slot" style="z-index: 1">
        <h2
          class="
            position-absolute
            m-3
            pt-1
            mr-5
            text-left
            font-weight-bold
            pointer-none
          "
          style="letter-spacing: 0.1px"
        >
          Which appointment are you going to verify/unverify?
        </h2>
        <button
          class="btn p-0 pr-3 pt-3 border-0"
          :disabled="
            bulkSessionsFilter.isVerifying || bulkSessionsFilter.isUnverifying
          "
          @click="closeBulkSessionModal"
        >
          <i class="fas fa-times fa-lg"></i>
        </button>
      </div>
      <div class="card-body mt-4">
        <div class="row border-bottom pb-4">
          <div
            class="col-12 col-sm-6 mb-3"
            v-if="user && !user.isPatient && false"
          >
            <select
              v-model="bulkSessionsFilter.classification"
              class="form-control shadow-card"
            >
              <option value="" selected>Filter by Class</option>

              <option v-for="p in options.classes" :key="p.id" :value="p.id">
                {{ p.label }}
              </option>
            </select>
          </div>

          <div class="col-12 col-sm-6 mb-3" v-if="user && !user.isPatient">
            <select
              v-model="bulkSessionsFilter.service"
              class="form-control shadow-card"
            >
              <option value="" selected>Filter by Service</option>

              <option
                v-for="service in options.services"
                :key="service.id"
                :value="service.id"
              >
                {{ service.name }}
              </option>
            </select>
          </div>

          <div class="col-12 col-sm-6 mb-3">
            <select
              v-if="!user || !user.isPatient"
              v-model="bulkSessionsFilter.Patient"
              class="form-control shadow-card"
            >
              <option value="" selected>Filter by Specific User/Client</option>
              <optgroup
                label="Admins"
                v-if="
                  user &&
                  (user.isAdmin || user.isManagingAdmin) &&
                  options.adminUsers.length
                "
              >
                <option
                  v-for="p in options.adminUsers"
                  :key="p.id"
                  :value="p.id"
                >
                  {{ p.last }}, {{ p.first }}
                </option>
              </optgroup>
              <optgroup
                label="Providers"
                v-if="options.providerUsers.length && user && !user.isProvider"
              >
                <option
                  v-for="p in options.providerUsers"
                  :key="p.id"
                  :value="p.user_id || p.id"
                >
                  {{ p.last }}, {{ p.first }} {{ p.user_id }}
                </option>
              </optgroup>
              <optgroup label="Clients" v-if="options.patientUsers.length">
                <option
                  v-for="p in options.patientUsers"
                  :key="p.user_id || p.id"
                  :value="p.user_id || p.id"
                >
                  {{ p.last }}, {{ p.first }}
                </option>
              </optgroup>
            </select>
            <select
              v-if="user && user.isPatient"
              v-model="bulkSessionsFilter.Provider"
              class="form-control shadow-card"
            >
              <option value="" selected>Filter by Specific Provider</option>
              <option
                v-for="p in options.providerUsers"
                :key="p.id"
                :value="p.user_id || p.id"
              >
                {{ p.last }}, {{ p.first }} {{ p.user_id }}
              </option>
            </select>
          </div>

          <div
            class="col-12 col-sm-6 mb-3"
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
          >
            <select
              v-model="bulkSessionsFilter.Provider"
              class="form-control shadow-card"
            >
              <option value="" selected>
                Filter by Specific Provider/Admin
              </option>

              <optgroup
                label="Admins"
                v-if="
                  user &&
                  (user.isAdmin || user.isManagingAdmin) &&
                  options.adminUsers.length
                "
              >
                <option
                  v-for="p in options.adminUsers"
                  :key="p.id"
                  :value="p.id"
                >
                  {{ p.last }}, {{ p.first }}
                </option>
              </optgroup>
              <optgroup label="Providers" v-if="options.providerUsers.length">
                <option
                  v-for="p in options.providerUsers"
                  :key="p.id"
                  :value="p.id"
                >
                  {{ p.last }}, {{ p.first }}
                </option>
              </optgroup>
            </select>
          </div>

          <div class="col-12 col-sm-6 mb-3" v-if="user && !user.isPatient">
            <select
              v-model="bulkSessionsFilter.billableStatus"
              class="form-control shadow-card"
            >
              <option value="" selected>Filter by Billable Status</option>

              <option v-for="p in billableStatuses" :key="p.id" :value="p.id">
                {{ p.label }}
              </option>
            </select>
          </div>

          <div class="d-flex col-12 mb-3 mt-2">
            <div class="custom-control custom-checkbox">
              <input
                type="radio"
                name="bulkDateType"
                id="bulk-date-range-enabled"
                value="dateRange"
                class="custom-control-input"
                v-model="bulkSessionsFilter.dateRangeOption"
              />
              <label
                class="custom-control-label mr-3 font-weight-bold"
                for="bulk-date-range-enabled"
                ><span class="pointer-none">Date Range</span></label
              >
            </div>
            <div class="custom-control custom-checkbox ml-3">
              <input
                type="radio"
                name="bulkDateType"
                id="bulk-date-custom-enabled"
                value="customDates"
                class="custom-control-input"
                v-model="bulkSessionsFilter.dateRangeOption"
              />
              <label
                class="custom-control-label font-weight-bold"
                for="bulk-date-custom-enabled"
                ><span class="pointer-none">Custom Dates</span></label
              >
            </div>
          </div>
          <div
            class="row m-0 col-12 px-0 align-items-center"
            v-if="bulkSessionsFilter.dateRangeOption == 'dateRange'"
          >
            <div class="col-12">
              <v-date-picker
                v-model="bulkSessionsFilter.range"
                mode="date"
                :masks="{ input: 'YYYY-MM-DD' }"
                is-range
              >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div
                    class="
                      d-flex
                      justify-content-start
                      align-items-center
                      form-group
                      mb-0
                    "
                  >
                    <input
                      class="px-2 py-1 form-control shadow-card"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                    />
                    <div
                      style="width: 24px; min-width: 24px"
                      class="text-center font-weight-bold"
                    >
                      -
                    </div>
                    <input
                      class="px-2 py-1 form-control shadow-card"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
          <div
            class="
              d-flex
              justify-content-between
              col-12 col-sm-8
              ml-auto
              mr-auto
              py-0
            "
            v-if="bulkSessionsFilter.dateRangeOption == 'customDates'"
          >
            <v-calendar
              :attributes="bulkDateAttributes"
              @dayclick="onBulkDayClick"
              is-expanded
            />
          </div>
        </div>
        <div
          class="saving-note-container"
          v-if="
            bulkSessionsFilter.isVerifying || bulkSessionsFilter.isUnverifying
          "
        >
          <div class="saving-note alert alert-info m-0 mb-1 py-1" role="alert">
            This may take few moments...
          </div>
        </div>

        <div class="mb-2 mt-5 d-flex justify-content-center">
          <save
            :saving="bulkSessionsFilter.isUnverifying"
            :shouldDisable="bulkSessionsFilter.isVerifying"
            iconType="times-circle"
            @click="verifyUnverifyBulkSessions()"
            classes="btn btn-danger mr-4"
            savingText="Unverifying Appointments"
            >Unverify Appointments</save
          >
          <save
            :saving="bulkSessionsFilter.isVerifying"
            :shouldDisable="bulkSessionsFilter.isUnverifying"
            @click="verifyUnverifyBulkSessions(true)"
            savingText="Verifying Appointments"
            classes="btn btn-theme"
            >Verify Appointments</save
          >
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import dateFormat from "@/utils/dateFormat";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "calendar-bulk-modal",
  props: {
    options: {
      type: Object,
      required: true,
    },
    initialFilter: {
      default: {},
    },
    profileProviderUserId: {
      type: Number,
      default: 0,
    },
    profilePatientUserId: {
      type: Number,
      default: 0,
    },
    profileAdminUserId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      bulkSessionsFilter: {
        AppStatus: "",
        billableStatus: "",
        classification: "",
        service: "",
        Patient: "",
        Provider: "",
        isVerifying: false,
        isUnverifying: false,
        dateRangeOption: "dateRange",
        range: {
          start: new Date(),
          end: new Date(),
        },
        customDates: [],
        customDatesIds: [],
      },
      billableStatuses: [
        { label: "Billable", id: "billable" },
        { label: "Non Billable", id: "non-billable" },
      ],
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    bulkDateAttributes() {
      return this.bulkSessionsFilter.customDates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
  },
  methods: {
    dateFormat: dateFormat,
    dragMouseDown(event) {
      const draggableContainer = this.$refs.draggableContainer.parentElement;
      let shiftX =
        event.clientX - draggableContainer.getBoundingClientRect().left;
      let shiftY =
        event.clientY - draggableContainer.getBoundingClientRect().top;

      function moveAt(pageX, pageY) {
        draggableContainer.style.left = pageX - shiftX + "px";
        draggableContainer.style.top = pageY - shiftY + "px";
      }

      function onMouseMove(event) {
        moveAt(event.pageX, event.pageY);
      }

      window.addEventListener("mousemove", onMouseMove);

      window.onmouseup = function () {
        window.removeEventListener("mousemove", onMouseMove);
        window.onmouseup = null;
      };
    },
    onBulkDayClick: function (day) {
      const idx = this.bulkSessionsFilter.customDatesIds.findIndex(
        (id) => id === day.id
      );
      if (idx >= 0) {
        this.bulkSessionsFilter.customDates.splice(idx, 1);
        this.bulkSessionsFilter.customDatesIds.splice(idx, 1);
      } else if (!day.el.classList.contains("is-disabled")) {
        this.bulkSessionsFilter.customDates.push(day.date);
        this.bulkSessionsFilter.customDatesIds.push(day.id);
      }
    },
    getAllBulkSelectedDates: function () {
      const result = [];
      this.bulkSessionsFilter.customDates.forEach((item) => {
        const startDateTime = `${dateFormat(
          item,
          "YYYY-MM-DD"
        )}T00:00:00.000000Z`;
        const endDateTime = `${dateFormat(
          item,
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;

        const canadaOffsetStart = dayjs
          .tz(new Date(startDateTime), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(endDateTime), "America/Toronto")
          .utcOffset();
        const start_time = dayjs(startDateTime)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();

        const end_time = dayjs(endDateTime)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();
        result.push({ start_time: start_time, end_time: end_time });
      });
      return result;
    },
    openBulkSessionsModal: function () {
      this.bulkSessionsFilter = {
        ...JSON.parse(JSON.stringify(this.initialFilter)),
        service: "",
        isVerifying: false,
        isUnverifying: false,
        dateRangeOption: "dateRange",
        range: {
          start: new Date(),
          end: new Date(),
        },
        customDates: [],
        customDatesIds: [],
      };
      if (this.profilePatientUserId) {
        this.bulkSessionsFilter.Patient = this.profilePatientUserId;
      } else if (this.profileProviderUserId) {
        this.bulkSessionsFilter.Provider = this.profileProviderUserId;
      } else if (this.profileAdminUserId) {
        this.bulkSessionsFilter.Provider = this.profileAdminUserId;
      }
      if (this.assignedToMe) {
        this.bulkSessionsFilter.Provider = this.user.id;
      }
      this.$modal.show("bulk-sessions-modal");
    },
    closeBulkSessionModal: function () {
      this.$modal.hide("bulk-sessions-modal");
    },
    verifyUnverifyBulkSessions: function (verify) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `Are you sure you want to ${
            !verify ? "un" : ""
          }verify the filtered appointements?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${!verify ? "un" : ""}verify!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            const payload = {
              billableStatus: vm.bulkSessionsFilter.billableStatus,
              user_1: vm.bulkSessionsFilter.Provider,
              user_2: vm.bulkSessionsFilter.Patient,
              classes: vm.bulkSessionsFilter.classification,
              service: vm.bulkSessionsFilter.service,
            };
            if (vm.bulkSessionsFilter.dateRangeOption == "dateRange") {
              const startDateTime = `${dateFormat(
                vm.bulkSessionsFilter.range.start,
                "YYYY-MM-DD"
              )}T00:00:00.000000Z`;
              const endDateTime = `${dateFormat(
                vm.bulkSessionsFilter.range.end,
                "YYYY-MM-DD"
              )}T23:59:59.000000Z`;

              const canadaOffsetStart = dayjs
                .tz(new Date(startDateTime), "America/Toronto")
                .utcOffset();
              const canadaOffsetEnd = dayjs
                .tz(new Date(endDateTime), "America/Toronto")
                .utcOffset();
              payload.start_time = dayjs(startDateTime)
                .add(canadaOffsetStart, "minute")
                .utc()
                .format();

              payload.end_time = dayjs(endDateTime)
                .add(canadaOffsetEnd, "minute")
                .utc()
                .format();
            } else if (vm.bulkSessionsFilter.dateRangeOption == "customDates") {
              payload.custom_dates = vm.getAllBulkSelectedDates();
            }

            if (verify) {
              vm.bulkSessionsFilter.isVerifying = true;
            } else {
              vm.bulkSessionsFilter.isUnverifying = true;
            }
            vm.$http
              .put(`appointments/${!verify ? "un" : ""}verify-bulk`, payload)
              .then(
                (res) => {
                  vm.bulkSessionsFilter.isVerifying = false;
                  vm.bulkSessionsFilter.isUnverifying = false;
                  this.$emit("refetchCalendar");
                  this.closeBulkSessionModal();
                  Swal.fire({
                    title: `${verify ? "Verified" : "Unverified"} Successfully`,
                    text: res.data?.message,
                    icon: "success",
                  });
                },
                (err) => {
                  this.$emit("refetchCalendar");
                  let html =
                    err?.data?.error?.message?.error ||
                    err?.data?.error?.message ||
                    "Something went wrong...";
                  if (err.data.errors) {
                    html =
                      '<div class="alert alert-light-danger" role="alert">';
                    err.data.errors.forEach((error) => {
                      const startTime = dayjs(error.start_time)
                        .add(-1 * dayjs(error.start_time).utcOffset(), "minute")
                        .format("YYYY-MM-DD hh:mm (A)");
                      const endTime = dayjs(error.end_time)
                        .add(-1 * dayjs(error.end_time).utcOffset(), "minute")
                        .format("YYYY-MM-DD hh:mm (A)");
                      html += `<div>${error.message}<br><span class='small font-weight-bold' style='opacity: 0.7;'>${startTime}  -  ${endTime}</span></div><hr>`;
                    });
                  } else if (err.data.error && err.data.message) {
                    html = err.data.message;
                  }
                  Swal.fire({
                    icon: "warning",
                    html,
                  });
                  vm.bulkSessionsFilter.isVerifying = false;
                  vm.bulkSessionsFilter.isUnverifying = false;
                }
              );
          }
        });
    },
  },
};
</script>

<style lang="scss">
.pointer-none {
  pointer-events: none;
}
.bulk-sessions-modal {
  .main-modal-container {
    cursor: move;
    min-height: 450px;
  }
  .v--modal-box {
    overflow: visible;
    min-height: 450px;
    max-height: 100%;
    transition: none;

    @media (max-width: 768px) {
      max-height: 96vh;
      overflow: hidden;
      .card-body {
        max-height: calc(96vh - 47px);
        overflow-y: auto;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.saving-note-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 112px;
  left: 0;
  right: 0;
  text-align: center;
  .saving-note {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
}
</style>
