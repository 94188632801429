<template>
  <div>
    <div class="calendar-print-header">
      <div class="row m-0">
        <div class="col-4">
          <p class="mb-0" v-if="selectedPatient">
            Client: {{ selectedPatient.last }}, {{ selectedPatient.first }}
          </p>
          <p class="mb-0" v-if="profileAdminUserId && user">
            Admin: {{ user.last }}, {{ user.first }}
          </p>
          <p
            class="mb-0"
            v-if="
              selectedPatient &&
              patientData &&
              companyProfile &&
              companyProfile.show_insurer_in_report &&
              patientData.insurer
            "
          >
            Insurer: {{ patientData.insurer }}
          </p>
          <p
            class="mb-0"
            v-if="
              selectedPatient &&
              patientData &&
              companyProfile &&
              companyProfile.show_claim_number_in_report &&
              patientData.claim_number
            "
          >
            Claim #: {{ patientData.claim_number }}
          </p>
          <p
            class="mb-0"
            v-if="
              selectedPatient &&
              patientData &&
              companyProfile &&
              companyProfile.show_funding_source_in_report &&
              patientData.funding_source
            "
          >
            Funding Source: {{ patientData.funding_source }}
          </p>
          <p class="mb-0" v-if="selectedProvider">
            Admin/Provider: {{ selectedProvider.last }},
            {{ selectedProvider.first }}
          </p>
        </div>
        <div class="col-4 text-center mt-4">
          <img
            class="company-logo"
            v-if="companyProfile"
            :src="companyProfile.logo || companyLogoPlaceholder"
          />
        </div>
        <div class="col-4 text-right">
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_claim_number_in_report &&
              companyProfile.claim_number
            "
          >
            <label>Claim Number: </label>{{ companyProfile.claim_number }}
          </p>
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_lawyer_in_report &&
              companyProfile.lawyer
            "
          >
            <label>Lawyer: </label>{{ companyProfile.lawyer }}
          </p>
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_location_in_report &&
              companyProfile.location
            "
          >
            <label>Location: </label>{{ companyProfile.location }}
          </p>
        </div>
      </div>
    </div>
    <div class="print-only">
      <table class="table table-striped mb-0 calendar-table">
        <thead>
          <tr>
            <div class="calendar-print-table-header"></div>
          </tr>
          <tr>
            <th colspan="9" class="text-center large-font">
              {{ calendarTitle }}
            </th>
          </tr>
          <tr>
            <th>Date</th>
            <th>Duration</th>
            <th>Service</th>
            <th>Provider</th>
            <th>Client</th>
            <th>Reason</th>
            <th>Memo</th>
            <th>Type</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in eventsToPrint" :key="`event_${event.id}`">
            <td>{{ dateFormat(event.start, "human") }}</td>
            <td>
              {{
                `${event.hours + event.sub_services_quantity}

                h${event.minutes > 0 ? " " + event.minutes + "m" : ""}`
              }}
            </td>
            <td>{{ event.main_service_name }}</td>
            <td>{{ event.provider_name }}</td>
            <td>{{ event.patient_name }}</td>
            <td>{{ event.reason }}</td>
            <td>{{ event.memo }}</td>
            <td class="text-capitalize">{{ event.type }}</td>
            <td class="text-capitalize">{{ event.status }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div class="calendar-print-table-footer"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="calendar-print-footer pt-2">
      <div class="row m-0">
        <div class="col-6">
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_name_in_report &&
              companyProfile.name
            "
          >
            {{ companyProfile.name }}
          </p>
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_email_in_report &&
              companyProfile.email
            "
          >
            {{ companyProfile.email }}
          </p>
        </div>
        <div class="col-6 text-right">
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_phone_number_in_report &&
              companyProfile.phone_number
            "
          >
            {{ companyProfile.phone_number }}
          </p>
        </div>
        <div class="col-12">
          <p
            class="mb-0"
            v-if="
              companyProfile &&
              companyProfile.show_bio_in_report &&
              companyProfile.bio
            "
          >
            {{ companyProfile.bio }}
          </p>
          <p
            class="mb-0 text-center"
            v-if="
              companyProfile &&
              companyProfile.show_url_in_report &&
              companyProfile.url
            "
          >
            {{ companyProfile.url }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import dateFormat from "@/utils/dateFormat";

export default {
  name: "calendar-print",
  props: {
    eventsToPrint: Array,
    profileAdminUserId: Number,
    selectedPatient: Object,
    selectedProvider: Object,
    patientData: Object,
    companyProfile: {},
    calendarTitle: String,
  },
  data() {
    return {
      loading: false,
      filter: {
        AppStatus: "",
        billableStatus: "",
        classification: "",
        Patient: "",
        Provider: "",
        assignedToMe: false,
      },
      billableStatuses: [
        { label: "Billable", id: "billable" },
        { label: "Non Billable", id: "non-billable" },
      ],
      companyLogoPlaceholder: require("@/assets/img/c3-logo.png"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    dateFormat: dateFormat,
  },
};
</script>

<style lang="scss">
.calendar-print-table-header {
  height: 240px;
}
.calendar-print-table-footer {
  height: 140px;
}

.calendar-print-header {
  height: 220px;
  display: none;
  overflow: hidden;
  top: 0;
  border-bottom: 1px solid;

  .company-logo {
    max-width: 160px;
    max-height: 160px;
  }
}
.calendar-print-footer {
  height: 120px;
  display: none;
  bottom: 0;
  border-top: 1px solid;
}
.calendar-print-header,
.calendar-print-footer {
  position: fixed;
  width: 100%;
  left: 0;
}

@media print {
  .calendar-print-header,
  .calendar-print-footer {
    display: block;
  }

  .calendar-table {
    margin-top: -110px;
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
</style>
