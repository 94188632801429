<template>
  <modal
    class="availability-session-modal"
    name="availability-session-modal"
    transition="pop-out"
    width="750"
    height="96%"
    :resizable="true"
    :adaptive="true"
  >
    <div
      class="position-relative h-100"
      ref="draggableAvailabilityContainer"
      @mousedown="dragAvailabilityMouseDown"
    >
      <div class="vm--top-right-slot" style="z-index: 1">
        <button
          class="btn p-0 pr-3 pt-3 border-0"
          @click="closeUserAvailabilityModal"
        >
          <i class="fas fa-times fa-lg"></i>
        </button>
      </div>
      <div class="card-body" v-if="availabilityUserId">
        <availability :current-user-id="availabilityUserId" :noEdit="true" />
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  name: "calendar-availability-modal",
  data() {
    return {
      availabilityUserId: null,
    };
  },
  methods: {
    dragMouseDown(event) {
      const draggableContainer = this.$refs.draggableContainer.parentElement;
      let shiftX =
        event.clientX - draggableContainer.getBoundingClientRect().left;
      let shiftY =
        event.clientY - draggableContainer.getBoundingClientRect().top;

      function moveAt(pageX, pageY) {
        draggableContainer.style.left = pageX - shiftX + "px";
        draggableContainer.style.top = pageY - shiftY + "px";
      }

      function onMouseMove(event) {
        moveAt(event.pageX, event.pageY);
      }

      window.addEventListener("mousemove", onMouseMove);

      window.onmouseup = function () {
        window.removeEventListener("mousemove", onMouseMove);
        window.onmouseup = null;
      };
    },
    dragAvailabilityMouseDown(event) {
      const draggableContainer =
        this.$refs.draggableAvailabilityContainer.parentElement;
      let shiftX =
        event.clientX - draggableContainer.getBoundingClientRect().left;
      let shiftY =
        event.clientY - draggableContainer.getBoundingClientRect().top;

      function moveAvailabilityAt(pageX, pageY) {
        draggableContainer.style.left = pageX - shiftX + "px";
        draggableContainer.style.top = pageY - shiftY + "px";
      }

      function onMouseAvailabilityMove(event) {
        moveAvailabilityAt(event.pageX, event.pageY);
      }

      window.addEventListener("mousemove", onMouseAvailabilityMove);

      window.onmouseup = function () {
        window.removeEventListener("mousemove", onMouseAvailabilityMove);
        window.onmouseup = null;
      };
    },
    openUserAvailabilityModal: function (userId) {
      this.availabilityUserId = parseInt(`${userId}`.split("_")[0]);
      this.$modal.show("availability-session-modal");
    },
    closeUserAvailabilityModal: function () {
      this.availabilityUserId = null;
      this.$modal.hide("availability-session-modal");
    },
  },
};
</script>

<style lang="scss">
.availability-session-modal {
  .v--modal {
    background-color: var(--body-bg-color);
  }
  &.v--modal-overlay {
    z-index: 2001;
  }
  .card-body {
    cursor: move;
    height: calc(100% - 35px);
    overflow: auto;
    padding: 12px;
  }
  .availability-page {
    margin-top: 0 !important;
  }

  .day-checkbox-container {
    padding-left: 0;
    input {
      display: none;
    }
  }
  .form-control:disabled {
    opacity: 1;
  }
}
</style>
